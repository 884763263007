const state = () => ({
  homepageSearchCollections: [],
  homepageSearchWineClubs: [],
  homepageSearchLinks: [],
  homepageSearchExecuting: false,
  homepageSearchOpen: false,
  homepageSearchResults: [],
  homepageSearchCount: 0,
  homepageIsSingleCollectionResults: false,
})

const mutations = {
  SET_HOMEPAGE_SEARCH_OPEN(state, value) {
    state.homepageSearchOpen = value
  },
  CLEAR_HOMEPAGE_SEARCH(state) {
    state.homepageSearchCollections = []
    state.homepageSearchWineClubs = []
    state.homepageSearchLinks = []
    state.homepageSearchExecuting = false
    state.homepageSearchResults = []
    state.homepageSearchCount = 0
    state.homepageSearchOpen = false
    state.homepageIsSingleCollectionResults = false
  },
  SET_IS_SINGLE_COLLECTION_RESULTS(state) {
    state.homepageIsSingleCollectionResults = true
  },
  BEFORE_SEARCH(state) {
    state.homepageSearchExecuting = true
    state.homepageIsSingleCollectionResults = false
  },
  SET_FROM_API_RESPONSE(state, resp) {
    if (resp.data === 'no data') {
      state.homepageSearchCount = 0
      state.homepageSearchExecuting = false
      return []
    }
    state.homepageSearchResults = resp.data.results
    state.homepageSearchCollections = resp.data.collections
    if (resp.data.links !== undefined) {
      state.homepageSearchWineClubs = resp.data.links.filter(
        (l) => l.collection_type === 'membershiptype'
      )
      state.homepageSearchLinks = resp.data.links.filter(
        (l) => l.collection_type === 'link'
      )
    }
    state.homepageSearchCount = resp.data.count
    state.homepageSearchExecuting = false
  },
}

const actions = {
  setHomepageSearchOpen({ commit }, value) {
    commit('SET_HOMEPAGE_SEARCH_OPEN', value)
  },
  clearHomepageSearch({ commit }) {
    commit('CLEAR_HOMEPAGE_SEARCH')
    commit('SET_ANY_SEARCH_EXECUTING', false, { root: true })
  },
  async searchAndUpdateState({ commit }, params) {
    commit('BEFORE_SEARCH')
    let response = await this.$axios.get('/api/catalog/search/', { params })
    if (
      response.data.results.length === 0 &&
      response.data.collections.length === 1
    ) {
      // If there are no product results and only one collection, show this
      // collection and this collection's products
      params = {
        occasions__in: response.data.collections[0].slug,
      }
      response = await this.$axios.get('/api/catalog/search/', { params })
      commit('SET_IS_SINGLE_COLLECTION_RESULTS')
    }
    commit('SET_FROM_API_RESPONSE', response)
  },
  executeHomepageSearch({ commit, dispatch, getters, rootState }, query) {
    const params = {
      search: query,
    }
    if (query.length > 2) {
      commit('SET_ANY_SEARCH_EXECUTING', true, { root: true })
      dispatch('searchAndUpdateState', params)
      commit('SET_ANY_SEARCH_EXECUTING', false, { root: true })
    } else {
      commit('CLEAR_HOMEPAGE_SEARCH')
    }
  },
}

const getters = {
  homepageSearchCollections(state) {
    return state.homepageSearchCollections
  },
  homepageSearchWineClubs(state) {
    return state.homepageSearchWineClubs
  },
  homepageSearchLinks(state) {
    return state.homepageSearchLinks
  },
  homepageSearchExecuting(state) {
    return state.homepageSearchExecuting
  },
  homepageSearchOpen(state) {
    return state.homepageSearchOpen
  },
  homepageSearchResults(state) {
    return state.homepageSearchResults
  },
  homepageSearchCount(state) {
    return state.homepageSearchCount
  },
  homepageIsOnlyCollectionResults(state) {
    return (
      state.homepageSearchCollections.length > 0 &&
      state.homepageSearchResults.length < 1
    )
  },
  getHomepageStoreSearchUrl: (state, getters) => (
    query,
    validateResults = true
  ) => {
    const url = `/store/search/${query}/?ordering=-score`

    if (!validateResults) {
      return url
    }

    if (
      state.homepageIsSingleCollectionResults ||
      getters.homepageIsOnlyCollectionResults
    ) {
      return state.homepageSearchCollections[0].url
    } else if (query && state.homepageSearchResults.length > 0) {
      return `/store/search/${query}/?ordering=-score`
    }
    return '/store/'
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
