import isMobile from 'ismobilejs'
import { generateFilterMapping, processFacets } from './metadata'
import { urlToFilters } from '~/utils/filters/filters'

const PRODUCTS_PAGE_SIZE = {
  default: 48,
  mobile: 12,
}
const getPageSize = (mobile) =>
  PRODUCTS_PAGE_SIZE[mobile ? 'mobile' : 'default']

export const orderingFromUrl = (route, state, selectedFilters) => {
  // Occasions have a default sorting field,
  let defaultSorting = state.thisOccasion && state.thisOccasion.default_sorting
  if (!defaultSorting && state.occasions) {
    // Check if we have multiple occasions in the filter
    const occasionFilter = selectedFilters.find(
      (x) => x.category === 'occasions'
    )
    if (occasionFilter) {
      const firstOccasion = state.occasions
        .reduce((prev, curr) => [...prev, ...curr.items], [])
        .find((x) => x.slug === occasionFilter.key)
      defaultSorting = firstOccasion ? firstOccasion.default_sorting : null
    }
  }
  if (!defaultSorting) {
    const isOffer =
      route.params.category === 'is_offer' && route.params.slug === 'true'
    defaultSorting = isOffer ? '-start_datetime' : 'personalized'
  }
  return route.query.ordering || defaultSorting
}

export const getParamsFromFilters = (
  filters,
  page,
  pageSize,
  ordering,
  imageSearch
) => {
  const params = {}
  if (page) {
    params.page = page
  }
  if (pageSize) {
    params.page_size = pageSize
  }
  if (ordering) {
    params.ordering = ordering
  }
  if (imageSearch) {
    params.image_search = imageSearch
  }

  filters.forEach((filter) => {
    if (filter.category === 'is_offer') {
      const paramKey = filter.category + '__in'
      if (!params[paramKey]) {
        params[paramKey] = filter.key_as_string
      } else {
        params[paramKey] += '__' + filter.key_as_string
      }
    } else if (filter.category === 'notify-me') {
      params.notify_me = 'true'
    } else if (filter.category === 'personalized') {
      params.personalized = 'true'
    } else if (filter.category === 'search') {
      const paramKey = filter.category
      if (!params[paramKey]) {
        params[paramKey] = filter.key
      } else {
        params[paramKey] += '__' + filter.key
      }
    } else if (filter.category === 'custom_price') {
      if (filter.from) {
        params.price_excl_tax__gte = filter.from
      }
      if (filter.to !== undefined && filter.to !== null) {
        params.price_excl_tax__lte = filter.to
      }
    } else if (filter.category === 'custom_points') {
      if (filter.from) {
        params.expert_rating_range__gte = filter.from
      }
      if (filter.to) {
        params.expert_rating_range__lte = filter.to
      }
    } else if (filter.category === 'alcohol_percent') {
      if (filter.from) {
        params.alcohol_percent__gte = filter.from
      }
      if (filter.to) {
        params.alcohol_percent__lte = filter.to
      }
    } else if (filter.category === 'drinking_window') {
      if (filter.from) {
        params.drinking_window_end__gte = filter.from
      }
      if (filter.to) {
        params.drinking_window_start__lte = filter.to
      }
    } else if (filter.category === 'flavor_profile') {
      if (filter.from) {
        params[`${filter.key}_scale__gte`] = filter.from
      }
      if (filter.to !== undefined && filter.to !== null) {
        params[`${filter.key}_scale__lte`] = filter.to
      }
    } else {
      let paramKey = filter.category
      if (!filter.category.endsWith('__in')) {
        paramKey = filter.category + '__in'
      }
      if (!params[paramKey]) {
        params[paramKey] = filter.key
      } else {
        params[paramKey] += '__' + filter.key
      }
    }
  })

  return params
}

const state = () => ({
  searchProducts: [],
  searchFilters: [],
  searchFiltersMapping: {},
  categoryCircles: [],
  resultsCount: 0,
  pageSize: PRODUCTS_PAGE_SIZE.default,
  thisOccasion: {},
  productIDInView: undefined,
  carouselInView: undefined,
})

const mutations = {
  SET_SEARCH_FILTERS(state, payload) {
    state.searchFilters = payload
  },
  SET_SEARCH_FILTERS_MAPPING(state, payload) {
    state.searchFiltersMapping = payload
  },
  SET_SEARCH_PRODUCTS(state, payload) {
    state.searchProducts = payload
  },
  SET_CATEGORY_CIRCLES(state, payload) {
    // category circle payload structure varies, so eliminate nested data if present
    if (payload && !Array.isArray(payload)) {
      payload = payload.data
    }
    state.categoryCircles = payload
  },
  SET_THIS_OCCASION(state, payload) {
    state.thisOccasion = payload
  },
  SET_RESULTS_COUNT(state, payload) {
    state.resultsCount = payload
  },
  SET_PAGE_SIZE(state, payload) {
    state.pageSize = payload
  },
  SET_PRODUCT_ID_IN_VIEW(state, payload) {
    state.productIDInView = payload
  },
  SET_CAROUSEL_IN_VIEW(state, payload) {
    state.carouselInView = payload
  },
  PUSH_FILTERS(state, filters) {
    filters.forEach((filter) => {
      if (!state.searchFiltersMapping[filter.category])
        state.searchFiltersMapping[filter.category] = {}
      state.searchFiltersMapping[filter.category][filter.key] =
        state.searchFilters.push(filter) - 1
    })
  },
}

const actions = {
  async fetchSearchFilters(store) {
    const { commit, rootState } = store
    await this.$axios.get('/api/catalog/facets/').then((resp) => {
      const searchFilters = processFacets(rootState.metadata, resp.data.facets)
      const searchFiltersMapping = generateFilterMapping(searchFilters)
      commit('SET_SEARCH_FILTERS', searchFilters)
      commit('SET_SEARCH_FILTERS_MAPPING', searchFiltersMapping)
    })
  },
  fetchProducts(store, { route, request }) {
    const { commit, state } = store
    const mobile = isMobile(
      request ? request.headers['user-agent'] : window.navigator
    ).phone
    const pageSize = getPageSize(mobile)
    const filters = urlToFilters({ store, route })
    const params = getParamsFromFilters(
      filters,
      route.query.page || 1,
      pageSize,
      orderingFromUrl(route, state, filters),
      route.query.image_search
    )

    const pptoMatch = route.path.match(/.*add-to-order\/([^/]+)\//i)
    if (pptoMatch) {
      params.order_id = pptoMatch[1]
    }

    if (
      route.params.category === 'search' &&
      route.params.slug &&
      route.params.slug.length
    ) {
      commit('SET_SEARCH_OPEN', true)
    }

    return this.$axios.get('/api/catalog/search/', { params }).then((resp) => {
      commit('SET_SEARCH_PRODUCTS', resp.data.results)
      commit('SET_CATEGORY_CIRCLES', resp.data.category_circles_nav_results)
      commit('SET_RESULTS_COUNT', resp.data.count)
      commit('SET_PAGE_SIZE', pageSize)
    })
  },
  fetchOccasion({ commit, state, rootState, getters }, { route }) {
    commit('SET_THIS_OCCASION', {})
    let occasionSlug = null

    if (route.params.category === 'occasion') {
      occasionSlug = route.params.slug
    } else if (
      route.query.occasions__in &&
      route.query.occasions__in.split(/,|__/).length === 1
    ) {
      // Only set the occasion if there is a single occasion
      occasionSlug = route.query.occasions__in
    } else if (
      route.query.occasions &&
      route.query.occasions.split(/,|__/).length === 1
    ) {
      // Only set the occasion if there is a single occasion
      occasionSlug = route.query.occasions
    }

    if (occasionSlug) {
      return this.$axios
        .get('/api/catalog/retrieve/occasion/' + occasionSlug + '/')
        .then((resp) => {
          commit('SET_THIS_OCCASION', resp.data)
        })
    }
  },
  clearOccasion({ commit }) {
    commit('SET_THIS_OCCASION', {})
  },
  setProductIDInView({ commit }, productID) {
    commit('SET_PRODUCT_ID_IN_VIEW', productID)
  },
  setCarouselInView({ commit }, productID) {
    commit('SET_CAROUSEL_IN_VIEW', productID)
  },
  pushFilters({ commit }, filters) {
    commit('PUSH_FILTERS', filters)
  },
}

const getters = {
  searchFilters(state) {
    return state.searchFilters
  },
  searchFiltersMapping(state) {
    return state.searchFiltersMapping
  },

  searchFiltersByCategory: (state) => (category) => {
    if (state.searchFiltersMapping[category]) {
      return Object.values(state.searchFiltersMapping[category]).map(
        (index) => {
          return state.searchFilters[index]
        }
      )
    }
    return []
  },

  searchFilterByCategoryAndKey: (state) => (category, key) => {
    if (key === 'true') key = '1'
    else if (key === 'false') key = '0'
    if (
      state.searchFiltersMapping[category] &&
      state.searchFiltersMapping[category][key]
    ) {
      return state.searchFilters[state.searchFiltersMapping[category][key]]
    }
    return undefined
  },

  searchProducts(state) {
    return state.searchProducts
  },

  categoryCircles(state) {
    return state.categoryCircles
  },

  thisOccasion(state) {
    return state.thisOccasion
  },

  totalPages(state) {
    return Math.ceil(state.resultsCount / state.pageSize)
  },

  productIDInView(state) {
    return state.productIDInView
  },

  carouselInView(state) {
    return state.carouselInView
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
