function namedUrlToFilters({ store, route }, name) {
  if (route.params.category !== name || !store.getters.searchFilters)
    return undefined
  return [
    {
      category: name,
      key: 1,
      // We assume here the name is dash-separated (ex. notify-me)
      name: name
        .split('-')
        .map((w) => w.charAt(0).toUpperCase() + w.substring(1, w.length))
        .join(' '),
    },
  ]
}

function namedFiltersToUrl(filters, name) {
  if (
    filters.length === 1 &&
    filters.every((filter) => filter.category === name)
  ) {
    return `${name}/`
  }
  return undefined
}

export function notifyMeFiltersToUrl(filters) {
  return namedFiltersToUrl(filters, 'notify-me')
}

export function notifyMeUrlToFilters(x) {
  return namedUrlToFilters(x, 'notify-me')
}

export function personalizedFiltersToUrl(filters) {
  return namedFiltersToUrl(filters, 'personalized')
}

export function personalizedUrlToFilters(x) {
  return namedUrlToFilters(x, 'personalized')
}
