export default function ({ req, store }) {
  // If we are in the mobile app we need the header
  // and footer elements removed.
  const userAgent = process.server
    ? req.headers['user-agent']
    : navigator.userAgent
  const inMobileApp =
    userAgent &&
    userAgent
      .split(' ')
      .some((substring) => substring.startsWith('wa-mobile-app'))

  store.dispatch('setInMobileApp', inMobileApp)
}
