import isMobile from 'ismobilejs'

const FUTURES_PRODUCTS_PAGE_SIZE = {
  default: 48,
  mobile: 12,
}
const getFuturesPageSize = (mobile) =>
  FUTURES_PRODUCTS_PAGE_SIZE[mobile ? 'mobile' : 'default']

const state = () => ({
  futuresCart: {},
  futuresCartOpen: false,
  futuresProducts: [],
  futuresResultsCount: 0,
  futuresPageSize: FUTURES_PRODUCTS_PAGE_SIZE.default,
})

const mutations = {
  SET_FUTURES_CART(state, payload) {
    state.futuresCart = payload
  },
  TOGGLE_FUTURES_CART(state) {
    state.futuresCartOpen = !state.futuresCartOpen
  },
  SET_FUTURES_CART_OPEN(state, payload) {
    state.futuresCartOpen = payload
  },
  UPDATE_FUTURES_LINE_QTY(state, payload) {
    state.futuresCart.lines[payload.index].quantity = payload.quantity
  },
  SET_FUTURES_PRODUCTS(state, payload) {
    state.futuresProducts = payload
  },
  SET_FUTURES_RESULTS_COUNT(state, payload) {
    state.futuresResultsCount = payload
  },
  SET_FUTURES_PAGE_SIZE(state, payload) {
    state.futuresPageSize = payload
  },
}

const actions = {
  getFuturesCart({ commit, dispatch }) {
    return this.$axios
      .get('/api/basket/futures/', { params: { checkout: 1 } })
      .then((resp) => {
        commit('SET_FUTURES_CART', resp.data)
      })
  },
  async addToFuturesCart({ commit, state, dispatch }, payload) {
    try {
      const params = payload.isCheckout ? { checkout: 1 } : {}
      const resp = await this.$axios({
        method: 'POST',
        url: '/api/basket/add-product-futures/',
        data: payload,
        params,
      })
      if (!state.futuresCartOpen && payload.toggleCart !== false) {
        commit('TOGGLE_FUTURES_CART')
      }
      commit('SET_FUTURES_CART', resp.data)
    } catch (e) {
      throw e.response.data
    }
  },
  updateFuturesLineQuantity({ commit }, payload) {
    commit('UPDATE_FUTURES_LINE_QTY', payload)
  },
  doToggleFuturesCart({ commit }) {
    commit('TOGGLE_FUTURES_CART')
  },
  setFuturesCart({ commit }, payload) {
    commit('SET_FUTURES_CART', payload)
  },
  setFuturesCartOpen({ commit }, payload) {
    commit('SET_FUTURES_CART_OPEN', payload)
  },
  fetchFuturesProducts({ commit }, { request }) {
    const mobile = isMobile(
      request ? request.headers['user-agent'] : window.navigator
    ).phone
    const pageSize = getFuturesPageSize(mobile)
    const params = {
      pageSize,
    }

    return this.$axios.get('/api/catalog/futures/', { params }).then((resp) => {
      commit('SET_FUTURES_PRODUCTS', resp.data.results)
      commit('SET_FUTURES_RESULTS_COUNT', resp.data.count)
      commit('SET_FUTURES_PAGE_SIZE', pageSize)
    })
  },
}

const getters = {
  futuresCart: (state) => state.futuresCart,
  futuresCartOpen: (state) => state.futuresCartOpen,
  futuresProducts: (state) => state.futuresProducts,
  totalFuturesPages(state) {
    return Math.ceil(state.futuresResultsCount / state.futuresPageSize)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
