const state = () => ({
  pptoOrder: {},
})

const mutations = {
  SET_PPTO_ORDER(state, payload) {
    state.pptoOrder = payload
  },
}

const actions = {
  async getPPTOOrder({ commit }, payload) {
    const orderResponse = await this.$axios.get(
      '/api/customer/order/' + payload.orderId,
      {
        params: {
          is_ppto: true,
        },
      }
    )
    commit('SET_PPTO_ORDER', orderResponse.data)
    return orderResponse.data
  },
}

const getters = {
  pptoOrder(state) {
    return state.pptoOrder
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
