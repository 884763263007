import { deslugify, slugify } from '~/../common/utils/slugify'
import {
  customPriceFiltersToUrl,
  customPriceUrlToFilters,
} from '~/utils/filters/price'
import {
  drinkingWindowFiltersToUrl,
  drinkingWindowUrlToFilters,
} from '~/utils/filters/drinkingWindow'
import {
  customPointsFiltersToUrl,
  customPointsUrlToFilters,
} from '~/utils/filters/points'
import { flavorFiltersToUrl, flavorUrlToFilters } from '~/utils/filters/flavor'
import {
  alcoholPercentFiltersToUrl,
  alcoholPercentUrlToFilters,
} from '~/utils/filters/alcohol'
import { defaultRegionSlugsUrlToFilters } from '~/utils/filters/regionSlugs'

const defaultUrlToFilterParsers = []
defaultUrlToFilterParsers.push(customPriceUrlToFilters)
defaultUrlToFilterParsers.push(customPointsUrlToFilters)
defaultUrlToFilterParsers.push(alcoholPercentUrlToFilters)
defaultUrlToFilterParsers.push(drinkingWindowUrlToFilters)
defaultUrlToFilterParsers.push(flavorUrlToFilters)
defaultUrlToFilterParsers.push(defaultRegionSlugsUrlToFilters)

export const getCategoryForUrl = (filter) => {
  if (filter.category === 'categories') {
    return 'category__in'
  } else if (filter.category === 'is_offer') {
    return 'is_offer__in'
  } else {
    return filter.category
  }
}

export const getKeyForUrl = (filter) => {
  if (filter.category === 'is_offer') {
    return filter.key === 0 ? 'false' : 'true'
  } else if (filter.category === 'price') {
    return slugify(filter.key)
  } else {
    return filter.key
  }
}

export function defaultFilterToUrlParser(filters) {
  if (!filters.length) {
    return ''
  }
  const search = filters.find((f) => f.category === 'search')
  const searchKey = search ? search.key + '/' : ''
  const urlParams = new URLSearchParams()
  filters.forEach((filter) => {
    if (filter.category === 'search') return
    if (filter.category === 'custom_price') {
      customPriceFiltersToUrl(filter, urlParams)
      return
    }
    if (filter.category === 'custom_points') {
      customPointsFiltersToUrl(filter, urlParams)
      return
    }
    if (filter.category === 'drinking_window') {
      drinkingWindowFiltersToUrl(filter, urlParams)
      return
    }
    if (filter.category === 'alcohol_percent') {
      alcoholPercentFiltersToUrl(filter, urlParams)
      return
    }
    if (filter.category === 'flavor_profile') {
      flavorFiltersToUrl(filter, urlParams)
      return
    }
    const category = getCategoryForUrl(filter)
    const key = getKeyForUrl(filter)
    if (urlParams.has(category)) {
      urlParams.set(category, urlParams.get(category) + ',' + key)
    } else {
      urlParams.append(category, key)
    }
  })
  return `search/${searchKey}?${urlParams.toString()}`
}

// A mapping of other ways the backend has params to the frontend filter equivalent
const CATEGORY_NAME_ALTERNATIVES = {
  categories__in: 'categories',
  categories: 'categories',
  category: 'categories',
  category__in: 'categories',
  occasion: 'occasions',
  occasions__in: 'occasions',
  varietals__in: 'varietals',
  vintage__in: 'vintage',
  expert_reviewers__in: 'expert_reviewers',
  food_pairings__in: 'food_pairings',
  is_offer__in: 'is_offer',
  wineries__in: 'wineries',
}

export function defaultUrlToFilterParser({ store, params, query }) {
  if (!store.getters.searchFilters || !store.getters.searchFiltersMapping)
    return []
  const filters = []
  // Search filter
  if (params.category === 'search' && params.slug) {
    filters.push({
      key: params.slug,
      name: params.slug,
      category: 'search',
    })
  }
  if (query.search) {
    filters.push({
      key: query.search,
      name: query.search,
      category: 'search',
    })
  }

  const defaultFilters = defaultUrlToFilterParsers
    .flatMap((f) => f({ store, query }))
    .filter((df) => df)
  if (defaultFilters) filters.push(...defaultFilters)

  const queryCategories = new Set([
    ...Object.keys(store.getters.searchFiltersMapping),
    ...Object.keys(CATEGORY_NAME_ALTERNATIVES),
  ])
  queryCategories.forEach((queryCategory) => {
    const categoryName =
      CATEGORY_NAME_ALTERNATIVES[queryCategory] || queryCategory
    const slugs = query[queryCategory]
    if (!slugs) return
    slugs.split(/,|__/).forEach((slug) => {
      const filter = store.getters.searchFilterByCategoryAndKey(
        categoryName,
        slug
      )
      if (filter) filters.push(filter)
      else if (categoryName === 'is_offer') {
        if (slug === 'true') {
          filters.push({
            category: 'is_offer',
            name: 'Preorder (Limited Time Offer)',
            key: 1,
            slug: 'true',
            key_as_string: 'true',
          })
        } else {
          filters.push({
            category: 'is_offer',
            name: 'Ships Immediately (The Store)',
            key: 0,
            slug: 'false',
            key_as_string: 'false',
          })
        }
      } else {
        filters.push({
          key: slug,
          name: deslugify(slug),
          category: categoryName,
        })
      }
    })
  })
  return filters
}
