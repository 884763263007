const INITIAL_DATA = {
  showHeader: true,
  showMobileMenu: false,
  mobileMenuLevel: 1,
  showDesktopMenus: true,
  showTermsAndConditions: false,
}

const state = () => Object.assign({}, INITIAL_DATA)

const mutations = {
  SET_SHOW_HEADER(state, showHeader) {
    state.showHeader = showHeader
  },
  SET_SHOW_MOBILE_MENU(state, showMobileMenu) {
    state.showMobileMenu = showMobileMenu
  },
  SET_MOBILE_MENU_LEVEL(state, mobileMenuLevel) {
    state.mobileMenuLevel = mobileMenuLevel
  },
  SET_SHOW_DESKTOP_MENUS(state, showDesktopMenus) {
    state.showDesktopMenus = showDesktopMenus
  },
  SET_TERMS_AND_CONDITIONS(state, showTermsAndConditions) {
    state.showTermsAndConditions = showTermsAndConditions
  },
}
const actions = {
  setShowHeader({ commit }, showHeader) {
    commit('SET_SHOW_HEADER', showHeader)
  },
  setShowMobileMenu({ commit }, showMobileMenu) {
    commit('SET_SHOW_MOBILE_MENU', showMobileMenu)
  },
  setMobileMenuLevel({ commit }, mobileMenuLevel) {
    commit('SET_MOBILE_MENU_LEVEL', mobileMenuLevel)
  },
  setShowDesktopMenus({ commit }, showDesktopMenus) {
    commit('SET_SHOW_DESKTOP_MENUS', showDesktopMenus)
  },
  setTermsAndConditions({ commit }, showTermsAndConditions) {
    commit('SET_TERMS_AND_CONDITIONS', showTermsAndConditions)
  },
}

const getters = {
  showHeader(state) {
    return state.showHeader
  },
  showMobileMenu(state) {
    return state.showMobileMenu
  },
  mobileMenuLevel(state) {
    return state.mobileMenuLevel
  },
  showDesktopMenus(state) {
    return state.showDesktopMenus
  },
  showTermsAndConditions(state) {
    return state.showTermsAndConditions
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
