export default function ({ route, redirect, store }) {
  if (
    !route.path.endsWith('/') &&
    !(
      route.path.includes('sitemap') &&
      (route.path.endsWith('.html') || route.path.endsWith('.xml'))
    )
  ) {
    // This does not work in SPA mode
    redirect(301, route.path + '/', route.query)
  } else {
    setTimeout(() => store.dispatch('setSearchOpen', false), 2000)
  }
}
