function getPageTypeFromRoute(route) {
  const dest = route.toLowerCase()
  if (dest === '/') {
    return 'HOMEPAGE'
  } else if (dest.startsWith('/catalog')) {
    return 'PRODUCT'
  } else if (dest.startsWith('/checkout')) {
    return 'CART'
  } else if (dest.startsWith('/store')) {
    return 'CATEGORY'
  }
  return 'OTHER'
}

export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (typeof CustomEvent === 'undefined') {
      return
    }

    const sfRouteChangeEvent = new CustomEvent('surefoot:routeChange', {
      detail: {
        to: to.fullPath,
        from: from.fullPath,
        pageType: getPageTypeFromRoute(to.path), // Choices are HOMEPAGE, PRODUCT, CATEGORY, CART, or OTHER
      },
    })

    window.dispatchEvent(sfRouteChangeEvent)
  })
}
