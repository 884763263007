import { initAuthStorage, loadUser } from '~/../common/utils/user'
import { isIntraStoreNavigation } from '~/../common/utils/url'

export default async function ({
  $auth,
  $axios,
  $cookies,
  $moment,
  query,
  store,
  res,
}) {
  if (
    process.client &&
    isIntraStoreNavigation({ history: store.$router.history })
  ) {
    return
  }

  // Init auth storage on first route load on the server
  if (process.server) {
    initAuthStorage($auth)
  }

  // On first load on server, and everytime we switch routes on client, re-load user data
  await loadUser({
    $auth,
    $axios,
    $cookies,
    store,
    // Only pass query params if we are loading user first time on server
    query: process.server ? query : null,
    $moment,
    res,
  })
}
