/* eslint no-console: 0 */
export default function ({ $axios, store, route }) {
  const localHash = store.getters.env.buildHash
  if (process.client && localHash) {
    // Get hash from server
    console.log('retrieving build hash from server')
    return $axios.get('/buildHash.json').then((val) => {
      const remoteHash = val.data
      // Reload to retrieve new client if hashes do not match
      if (
        remoteHash.client !== localHash.client ||
        remoteHash.server !== localHash.server
      ) {
        console.log('local client hash: ' + localHash.client)
        console.log('local server hash: ' + localHash.server)

        console.log('remote client hash: ' + remoteHash.client)
        console.log('remote server hash: ' + remoteHash.server)

        console.log('app build hashes differ, reloading...')
        window.location.href = route.path
      }
    })
  }
}
