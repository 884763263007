import { searchUrlToMagicUrl } from '~/../common/utils/url'

export default async function ({ redirect, route, store }) {
  if (route.path.includes('/store/search/')) {
    const url = await searchUrlToMagicUrl(
      store.getters.searchFilters,
      route.fullPath
    )
    // url has changed, need to redirect
    if (!url.includes('/store/search/')) {
      return redirect(url)
    }
  }
}
