import { cloneDeep } from 'lodash'
import { categoryFiltersToUrl, categoryUrlToFilters } from './categories'
import { defaultFilterToUrlParser, defaultUrlToFilterParser } from './default'
import { syrahShirazFiltersToUrl, syrahShirazUrlToFilters } from './syrahShiraz'
import {
  pinotGrisGrigioFiltersToUrl,
  pinotGrisGrigioUrlToFilters,
} from './pinotGrisGrigio'
import {
  notifyMeFiltersToUrl,
  notifyMeUrlToFilters,
  personalizedFiltersToUrl,
  personalizedUrlToFilters,
} from './others'
import {
  dessertFortifiedFiltersToUrl,
  dessertFortifiedUrlToFilters,
  sweetFortifiedUrlToFilters,
} from './dessertFortified'
import {
  sparklingRoseFiltersToUrl,
  sparklingRoseUrlToFilters,
} from './sparklingRose'
import { regionSlugsFiltersToUrl, regionSlugsUrlToFilters } from './regionSlugs'

// Excluded filters will not be sent to the parsers
// and will be added back in as parameters on the request
const EXCLUDE_FILTERS = ['ordering', 'page', 'image_search']

/**
 * @typedef {any[]} Filters
 * @typedef {(filter: Filters) => string} FilterToUrl
 * @typedef {({ story: Vuex.Store, route: VueRouter.Route }) => Filters} UrlToFilter
 */

/**
 * @type {FilterToUrl[]}
 */
const filterToUrlParsers = []
/**
 * @type {UrlToFilter[]}
 */
const urlToFilterParsers = []

// Register parsers
filterToUrlParsers.push(dessertFortifiedFiltersToUrl)
filterToUrlParsers.push(syrahShirazFiltersToUrl)
filterToUrlParsers.push(pinotGrisGrigioFiltersToUrl)
filterToUrlParsers.push(sparklingRoseFiltersToUrl)
filterToUrlParsers.push(regionSlugsFiltersToUrl)
filterToUrlParsers.push(notifyMeFiltersToUrl)
filterToUrlParsers.push(personalizedFiltersToUrl)
filterToUrlParsers.push(categoryFiltersToUrl)

urlToFilterParsers.push(dessertFortifiedUrlToFilters)
urlToFilterParsers.push(sparklingRoseUrlToFilters)
urlToFilterParsers.push(sweetFortifiedUrlToFilters)
urlToFilterParsers.push(syrahShirazUrlToFilters)
urlToFilterParsers.push(pinotGrisGrigioUrlToFilters)
urlToFilterParsers.push(regionSlugsUrlToFilters)
urlToFilterParsers.push(notifyMeUrlToFilters)
urlToFilterParsers.push(personalizedUrlToFilters)
urlToFilterParsers.push(categoryUrlToFilters)

/**
 * Generates a URL and then splits it into a path and parameters. If the
 * optional store argument is provided, that will be used to generate the URL.
 * If not, or if a URL cannot be generated from the store, the default parser
 * is used.
 *
 * @param filters
 * @param store: Vuex.Store
 */
function splitPathAndParams(filters, store) {
  const relevantFilters = filters.filter(
    (f) => !EXCLUDE_FILTERS.includes(f.category)
  )
  let url = ''
  if (store) {
    url = filterToUrlParsers
      .map((f) => f(relevantFilters, { store }))
      .find((url) => url)
  }
  if (!url) {
    // No registered parsers match the filters, so use default
    url = defaultFilterToUrlParser(relevantFilters)
  }
  const [path, urlParams] = url.split('?', 2)
  const params = new URLSearchParams(urlParams || '')
  return [path, params]
}

/**
 * Constructs a URL from a base, parameters, and filters
 *
 * @param base The relative path of this page
 * @param params
 * @param filters
 */
function generateUrl(base, params, filters) {
  const search = filters.find((f) => f.category === 'search')
  const searchInBase = /\/search\//.test(base)
  const nonFilters = filters.filter((f) => EXCLUDE_FILTERS.includes(f.category))
  nonFilters.forEach((f) => {
    if (params.has(f.category)) {
      params.set(f.category, params.get(f.category) + '__' + f.key)
    } else {
      params.append(f.category, f.key)
    }
  })
  if (search && search.key && !searchInBase) {
    params.append('search', search.key)
  }
  params.sort()
  if (params.toString()) {
    base += '?' + params.toString()
  }
  return base
}

/**
 * Translates selectedFilters into a store URL,
 * based on registered URL handlers
 *
 * @param filters
 * @param {store: Vuex.Store} param0
 */
export function filtersToUrl(filters, { store }) {
  const [path, params] = splitPathAndParams(filters, store)
  return generateUrl(`/store/${path}`, params, filters)
}

/**
 * Translates selectedFilters into a URL with a given base
 * This method bypasses the regular pretty URL parsers
 *
 * @param base
 * @param filters
 */
export function simpleFiltersToUrl(base, filters) {
  const [, params] = splitPathAndParams(filters)
  return generateUrl(base, params, filters)
}

export function urlToFilters({ store, route }) {
  // Extract excluded filters
  const paramsString = route.fullPath.split('?', 2)[1] || ''
  const params = new URLSearchParams(paramsString)
  const excludedFilters = EXCLUDE_FILTERS.map((f) => ({
    category: f,
    key: params.get(f),
  })).filter((f) => f.key)
  let filters = urlToFilterParsers
    .map((f) => f({ store, route }))
    .find((filters) => filters)
  if (!filters) {
    // No registered parsers match the filters, so use default
    filters = defaultUrlToFilterParser({
      store,
      params: route.params,
      query: route.query,
    })
  }
  return filters
    .filter((f) => !EXCLUDE_FILTERS.includes(f.category))
    .concat(excludedFilters)
}

export function positionSorter(a, b) {
  if ((!a || !a.position) && (!b || !b.position)) return 0
  if (!a || !a.position) return 1
  if (!b || !b.position) return -1
  return a.position - b.position
}

export function filterifyMetadata(metadata, category) {
  const filters = []
  if (
    typeof metadata === 'object' &&
    !Array.isArray(metadata) &&
    metadata !== null
  ) {
    Object.keys(metadata).forEach((key) => {
      const item = cloneDeep(metadata[key])
      item.category = category
      item.key = key
      filters.push(item)
    })
  } else if (Array.isArray(metadata) && metadata !== null) {
    metadata.forEach((data) => {
      const item = data
      item.category = category
      item.key = data.slug
      filters.push(item)
    })
  }
  filters.sort(positionSorter)
  return filters
}
