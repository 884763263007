const INITIAL_DATA = {
  showFooter: true,
  showFooterBoxes: true,
}

const state = () => Object.assign({}, INITIAL_DATA)

const mutations = {
  SET_SHOW_FOOTER(state, showFooter) {
    state.showFooter = showFooter
  },
  SET_SHOW_FOOTER_BOXES(state, showFooterBoxes) {
    state.showFooterBoxes = showFooterBoxes
  },
}
const actions = {
  setShowFooter({ commit }, showFooter) {
    commit('SET_SHOW_FOOTER', showFooter)
  },
  setShowFooterBoxes({ commit }, showFooterBoxes) {
    commit('SET_SHOW_FOOTER_BOXES', showFooterBoxes)
  },
}

const getters = {
  showFooter(state) {
    return state.showFooter
  },
  showFooterBoxes(state) {
    return state.showFooterBoxes
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
