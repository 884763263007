<template>
  <div class="nuxt-error">
    <component :is="errorPage" :error="error" />
  </div>
</template>

<script>
import Error404 from '~/../common/components/Error/Error404.vue'
import Error500 from '~/../common/components/Error/Error500.vue'

export default {
  name: 'NuxtError',
  layout: 'default', // optional
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return Error404
      } // catch everything else
      return Error500
    },
  },
}
</script>
