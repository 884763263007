function isDev1Build() {
  return process.env.NUXT_ENV_BUILD === 'dev1'
}

function isQABuild() {
  return process.env.NUXT_ENV_BUILD === 'qa3'
}

function isProductionBuild() {
  return process.env.NUXT_ENV_BUILD === 'prod3'
}

module.exports = {
  isDev1Build,
  isQABuild,
  isProductionBuild,
}
