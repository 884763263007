export function pinotGrisGrigioFiltersToUrl(filters) {
  if (
    filters.length === 2 &&
    filters.every(
      (filter) =>
        filter.category === 'varietals' &&
        ['syrah', 'shiraz'].includes(filter.key)
    )
  ) {
    return `varietals/syrah-shiraz/`
  }
  return undefined
}

export function pinotGrisGrigioUrlToFilters({ store, route }) {
  if (
    route.params.category !== 'varietals' ||
    route.params.slug !== 'pinot-gris-grigio' ||
    !store.getters.searchFilters
  )
    return undefined
  return store.getters.searchFilters.filter(
    (filter) =>
      filter.category === 'varietals' &&
      ['pinot-gris', 'pinot-grigio'].includes(filter.key)
  )
}
