import {
  condenseRegionsToAttribute,
  flattenRegions,
  getChildSlugs,
} from '~/utils/filters/regionSlugs'

const state = () => ({
  allRegions: {}, // allRegions holds all possible region with the top level continents too
  allRegionsIndex: [],
  allRegionsFilters: {},
  selectedAllRegions: [],
})

const mutations = {
  SET_ALL_REGIONS(state, payload) {
    state.allRegions = payload
    for (const region of Object.values(state.allRegions)) {
      if (region.regions) {
        region.regions.forEach((r) => (state.allRegionsFilters[r.slug] = r))
      }
    }

    state.allRegionsIndex = flattenRegions({ regions: state.allRegionsFilters })
  },
  SET_SELECTED_ALL_REGIONS(state, payload) {
    state.selectedAllRegions = payload
  },
}

const actions = {
  fetchAllRegions({ state, commit }) {
    if (Object.keys(state.allRegions).length === 0) {
      this.$axios.get('/api/catalog/regions/').then((resp) => {
        commit('SET_ALL_REGIONS', resp.data)
      })
    }
  },
  selectAllRegion({ commit, state, rootState, getters }, { region }) {
    const selectedRegions = new Set(
      state.selectedAllRegions.concat(getChildSlugs(region))
    )
    selectedRegions.add(region.slug)
    commit(
      'SET_SELECTED_ALL_REGIONS',
      Array.from(selectedRegions).filter((s) => s)
    )
  },
  deselectAllRegion({ commit, state, rootState, getters }, { region }) {
    const deselectSlugs = getChildSlugs(region)
    deselectSlugs.push(region.slug)
    commit(
      'SET_SELECTED_ALL_REGIONS',
      state.selectedAllRegions.filter((s) => !deselectSlugs.includes(s))
    )
  },
  deselectAllAllRegions({ commit }) {
    commit('SET_SELECTED_ALL_REGIONS', [])
  },
}

const getters = {
  allRegions(state) {
    return state.allRegions
  },
  allRegionsIndex(state) {
    return state.allRegionsIndex
  },
  allRegionsFilters(state) {
    return state.allRegionsFilters
  },
  allRegionsWithSelections(state) {
    const setSelected = (region) => {
      const newRegion = {
        ...region,
        regions: undefined,
      }
      // Set children
      if (region.regions) {
        newRegion.regions = {}
        for (const r in region.regions) {
          newRegion.regions[r] = setSelected(region.regions[r])
        }
      }
      newRegion.checked =
        (newRegion.slugs &&
          newRegion.slugs.some((s) => state.selectedAllRegions.includes(s))) ||
        (newRegion.slug && state.selectedAllRegions.includes(newRegion.slug)) ||
        (newRegion.regions &&
          Object.values(newRegion.regions).some((s) => s.checked))
      return newRegion
    }
    return Object.entries(state.allRegions).reduce((filters, [key, filter]) => {
      filters[key] = setSelected(filter)
      return filters
    }, {})
  },
  selectedAllRegionSlugs(state) {
    return state.selectedAllRegions
  },
  selectedAllRegionChips(state) {
    return condenseRegionsToAttribute(
      state.allRegionsFilters,
      state.selectedAllRegions,
      'chipName'
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
