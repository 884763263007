export function sparklingRoseFiltersToUrl(filters) {
  if (
    filters.length === 2 &&
    filters.every(
      (filter) =>
        filter.category === 'categories' &&
        ['sparkling', 'rose'].includes(filter.key)
    )
  ) {
    return `sparkling-rose/`
  }
  return undefined
}

export function sparklingRoseUrlToFilters({ store, route }) {
  if (
    route.params.category !== 'sparkling-rose' ||
    !store.getters.searchFilters
  )
    return undefined
  return store.getters.searchFilters.filter(
    (filter) =>
      filter.category === 'categories' &&
      ['sparking', 'rose'].includes(filter.key)
  )
}
