import { deslugify } from '~/../common/utils/slugify'

export const getChildSlugs = (region) => {
  const slugs = [...region.slugs]
  for (const r in region.regions || {}) {
    slugs.push(...getChildSlugs(region.regions[r]))
  }
  return slugs
}

export function allChildrenRegionsAreChecked(region, selectedRegionSlugs) {
  const regions = Object.values(region.regions || {})
  if (
    regions.length > 0 &&
    regions.every((r) => allChildrenRegionsAreChecked(r, selectedRegionSlugs))
  ) {
    return true
  } else if (regions.length === 0) {
    let slugs = [...region.slugs]
    if (slugs.length === 0) slugs = [region.slug]
    return slugs.some((r) => selectedRegionSlugs.includes(r))
  }
  return false
}

export function condenseRegionsToAttribute(
  regionsFilters,
  selectedRegionSlugs,
  attribute
) {
  const getAttributes = (region) => {
    const attributeArray = []
    if (!region || !selectedRegionSlugs) {
      return attributeArray
    }
    if (!region.regions) {
      if (allChildrenRegionsAreChecked(region, selectedRegionSlugs)) {
        attributeArray.push(region[attribute])
      }
      return attributeArray
    }
    for (const r of Object.values(region.regions)) {
      if (allChildrenRegionsAreChecked(r, selectedRegionSlugs)) {
        attributeArray.push(r[attribute])
      } else {
        attributeArray.push(...getAttributes(r))
      }
    }
    return attributeArray
  }
  return getAttributes({ regions: regionsFilters })
}

export function regionSlugsFiltersToUrl(filters, { store }) {
  if (filters.some((f) => f.category !== 'region_slug')) return undefined
  const regionSlugs = condenseRegionsToAttribute(
    store.getters.regionsFilters,
    filters.map((f) => f.key),
    'slug'
  )
  if (regionSlugs.length === 1) {
    return `regions/${regionSlugs[0]}/`
  }
  if (filters.length === 1) {
    return `regions/${filters[0].key}/`
  }
  return undefined
}

export function getFullRegionSlug({ store, route, regionSlug }) {
  if (!store.getters.regionsIndex) return undefined
  const regionAndChildren = (region) => [
    region,
    ...Object.values(region.regions || {}).flatMap(regionAndChildren),
  ]
  // Get the matching slug, or find the combined
  // region that includes it
  const rootRegion =
    store.getters.regionsIndex.find((region) => region.slug === regionSlug) ||
    store.getters.regionsIndex.find(
      (region) => region.slugs && region.slugs.includes(regionSlug)
    )

  if (!rootRegion) return regionSlug

  return regionAndChildren(rootRegion).flatMap((region) =>
    (region.slugs || []).map((slug) => slug).join(',')
  )
}

export function regionSlugsUrlToFilters({ store, route }) {
  if (
    (route.params.category !== 'country' &&
      route.params.category !== 'regions') ||
    !route.params.slug ||
    !store.getters.regionsIndex
  )
    return undefined

  const regionAndChildren = (region) => [
    region,
    ...Object.values(region.regions || {}).flatMap(regionAndChildren),
  ]
  // Get the matching slug, or find the combined
  // region that includes it
  const rootRegion =
    store.getters.regionsIndex.find(
      (region) => region.slug === route.params.slug
    ) ||
    store.getters.regionsIndex.find(
      (region) => region.slugs && region.slugs.includes(route.params.slug)
    )

  // If no match, construct filter anyway
  if (!rootRegion)
    return [
      {
        category: 'region_slug',
        key: route.params.slug,
      },
    ]
  return regionAndChildren(rootRegion).flatMap((region) =>
    (region.slugs || []).map((slug) => ({
      category: 'region_slug',
      key: slug,
    }))
  )
}

export function defaultRegionSlugsUrlToFilters({ store, query }) {
  const regionSlugs = query.region_slug || query.region_slug__in
  if (!regionSlugs) return undefined
  const filters = []
  for (const regionSlug of regionSlugs.split(/,|__/)) {
    const slugIndex = store.getters.regionsIndex.find(
      (region) => region.slug === regionSlug
    )
    filters.push({
      category: 'region_slug',
      key: regionSlug,
      name: slugIndex ? slugIndex.name : deslugify(regionSlug),
      chipName: slugIndex ? slugIndex.chipName : deslugify(regionSlug),
    })
  }
  return filters.length > 0 ? filters : undefined
}

export function flattenRegions(region) {
  const flattened = []
  if (region && region.slug) {
    flattened.push({
      ...region,
    })
  }
  for (const subregion in region.regions || {}) {
    flattened.push(...flattenRegions(region.regions[subregion]))
  }
  return flattened
}
