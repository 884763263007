const INITIAL_DATA = {
  formData: { recipients: [] },
  formDataCompleted: false,
  returnData: null,
}

const state = () => Object.assign({}, INITIAL_DATA)

const mutations = {
  SET_FORM_DATA(state, data) {
    state.formData = data
  },
  SET_FORM_DATA_COMPLETED(state, isComplete) {
    state.formDataCompleted = isComplete
  },
  RESET_DATA(state, data) {
    Object.entries(INITIAL_DATA).forEach(([k, v]) => {
      state[k] = v
    })
    state.returnData = data
  },
}
const actions = {
  setGiftCardFormData({ commit }, data) {
    commit('SET_FORM_DATA', data)
  },
  setGiftCardFormDataCompleted({ commit }, isComplete) {
    commit('SET_FORM_DATA_COMPLETED', isComplete)
  },
  resetGiftCardForm({ commit }, data) {
    commit('RESET_DATA', data)
  },
}
const getters = {
  giftCardFormData(state) {
    return state.formData
  },
  giftCardFormDataWithValues(state) {
    // Map "other amount" to value field, if custom
    return {
      ...state.formData,
      recipients: state.formData.recipients.map((r) => ({
        ...r,
        value: r.value === 'custom' ? r.otherAmount : r.value,
      })),
    }
  },
  giftCardReturnData(state) {
    return state.returnData
  },
  giftCardTotal(state) {
    return getters
      .giftCardFormDataWithValues(state)
      .recipients.reduce((acc, cur) => acc + Number(cur.value), 0)
  },
  giftCardFormDataCompleted(state) {
    return state.formDataCompleted
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
