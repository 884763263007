const state = () => ({
  object: {
    style: {},
    producing_winery: {},
    default_record: null,
    style_scales: [],
    food_pairings: [],
    region_appellation: ['', ''],
    varietal: [],
  },
})

const mutations = {
  SET_OBJECT(state, data) {
    state.object = Object.assign({}, state.object, data)
  },
}

const actions = {
  setProductObject({ commit }, data) {
    commit('SET_OBJECT', data)
  },
  async fetchProductObject({ commit }, slug) {
    const product = (await this.$axios.get(`/api/product/${slug}/`)).data
    product.cart_count = 0

    if (product.wine_video_url && product.wine_video_url.includes('vimeo')) {
      try {
        const vimeoObject = await this.$axios.get(
          `https://vimeo.com/api/v2/video/${product.wine_video_url
            .split('/')
            .pop()}.json`,
          { timeout: 500 }
        )
        product.vimeo_thumbnail = vimeoObject.data[0].thumbnail_large.split(
          '_'
        )[0]
      } catch (e) {
        product.vimeo_thumbnail = require('~/assets/images/wineaccess-logo-round-black.svg')
      }
    }

    commit('SET_OBJECT', product)
  },
}

const getters = {
  productObject(state) {
    return state.object
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
