const state = () => ({
  searchCollections: [],
  searchWineClubs: [],
  searchLinks: [],
  searchExecuting: false,
  searchOpen: false,
  mobileSearchOpen: false,
  results: [],
  count: 0,
  ltoSearchCollections: [],
  ltoSearchWineClubs: [],
  ltoSearchLinks: [],
  ltoSearchExecuting: false,
  ltoSearchOpen: false,
  ltoResults: [],
  ltoCount: 0,
  anySearchExecuting: false,
  isSingleCollectionResults: false,
})

const mutations = {
  SET_SEARCH_OPEN(state, value) {
    state.searchOpen = value
  },
  SET_MOBILE_SEARCH_OPEN(state, value) {
    state.mobileSearchOpen = value
  },
  SET_ANY_SEARCH_EXECUTING(state, value) {
    state.anySearchExecuting = value
  },
  CLEAR_SEARCH(state) {
    state.searchCollections = []
    state.searchWineClubs = []
    state.searchLinks = []
    state.searchExecuting = false
    state.searchOpen = false
    state.anySearchExecuting = false
    state.isSingleCollectionResults = false
    state.results = []
    state.count = 0
  },
  LTO_CLEAR_SEARCH(state) {
    state.ltoSearchCollections = []
    state.ltoSearchWineClubs = []
    state.ltoSearchLinks = []
    state.ltoSearchExecuting = false
    state.anySearchExecuting = false
    state.ltoResults = []
    state.ltoCount = 0
  },
  SET_IS_SINGLE_COLLECTION_RESULTS(state) {
    state.isSingleCollectionResults = true
  },
  BEFORE_SEARCH(state) {
    state.searchExecuting = true
    state.anySearchExecuting = true
    state.isSingleCollectionResults = false
  },
  SET_FROM_API_RESPONSE(state, resp) {
    if (resp.data === 'no data') {
      state.count = 0
      state.searchExecuting = false
      state.anySearchExecuting = state.ltoSearchExecuting
      return []
    }
    state.results = resp.data.results
    state.searchCollections = resp.data.collections
    if (resp.data.links !== undefined) {
      state.searchWineClubs = resp.data.links.filter(
        (l) => l.collection_type === 'membershiptype'
      )
      state.searchLinks = resp.data.links.filter(
        (l) => l.collection_type === 'link'
      )
    }
    state.count = resp.data.count
    state.searchExecuting = false
    state.anySearchExecuting = state.ltoSearchExecuting
  },
  LTO_EXECUTE_SEARCH(state, params) {
    state.ltoSearchExecuting = true
    state.anySearchExecuting = true
    params = { ...params, ...{ is_offer__in: 'true' } }
    this.$axios.get('/api/catalog/search/', { params }).then((resp) => {
      if (resp.data === 'no data') {
        state.ltoCount = 0
        state.ltoSearchExecuting = false
        state.anySearchExecuting = state.searchExecuting
        return []
      }
      state.ltoResults = resp.data.results
      state.ltoSearchCollections = resp.data.collections
      if (resp.data.links) {
        state.ltoSearchWineClubs = resp.data.links.filter(
          (l) => l.collection_type === 'membershiptype'
        )
        state.ltoSearchLinks = resp.data.links.filter(
          (l) => l.collection_type === 'link'
        )
      }
      state.ltoCount = resp.data.count
      state.ltoSearchExecuting = false
      state.anySearchExecuting = state.searchExecuting
    })
  },
  LTO_SET_SEARCH_RESULTS(state, results) {
    state.ltoResults = results
  },
}

const actions = {
  setSearchOpen({ commit }, value) {
    commit('SET_SEARCH_OPEN', value)
  },
  setMobileSearchOpen({ commit }, value) {
    commit('SET_MOBILE_SEARCH_OPEN', value)
  },
  clearSearch({ commit }) {
    commit('CLEAR_SEARCH')
  },
  ltoClearSearch({ commit }) {
    commit('LTO_CLEAR_SEARCH')
  },
  async searchAndUpdateState({ commit }, params) {
    commit('BEFORE_SEARCH')
    let response = await this.$axios.get('/api/catalog/search/', { params })
    if (
      response.data.results.length === 0 &&
      response.data.collections.length === 1
    ) {
      // If there are no product results and only one collection, show this
      // collection and this collection's products
      params = {
        occasions__in: response.data.collections[0].slug,
      }
      response = await this.$axios.get('/api/catalog/search/', { params })
      commit('SET_IS_SINGLE_COLLECTION_RESULTS')
    }
    commit('SET_FROM_API_RESPONSE', response)
  },
  executeSearch({ dispatch, commit, getters, rootState }, query) {
    const params = {
      search: query,
    }
    if (query.length > 2) {
      dispatch('searchAndUpdateState', params)
    } else {
      commit('CLEAR_SEARCH')
    }
  },
  ltoExecuteSearch({ commit, getters, rootState }, query) {
    const params = {
      search: query,
    }
    if (query.length >= 0) {
      commit('LTO_EXECUTE_SEARCH', params)
    } else {
      commit('LTO_CLEAR_SEARCH')
    }
  },
  ltoExecuteEmptySearch({ commit }) {
    commit('LTO_EXECUTE_SEARCH', { page_size: 3, page: 1 })
  },
  ltoSetSearchResults({ commit }, searchResults) {
    commit('LTO_SET_SEARCH_RESULTS', searchResults)
  },
}

const getters = {
  searchCollections(state) {
    return state.searchCollections
  },
  searchWineClubs(state) {
    return state.searchWineClubs
  },
  searchLinks(state) {
    return state.searchLinks
  },
  searchExecuting(state) {
    return state.searchExecuting
  },
  ltoSearchExecuting(state) {
    return state.ltoSearchExecuting
  },
  anySearchExecuting(state) {
    return state.anySearchExecuting
  },
  searchOpen(state) {
    return state.searchOpen
  },
  mobileSearchOpen(state) {
    return state.mobileSearchOpen
  },
  results(state) {
    return state.results
  },
  ltoResults(state) {
    return state.ltoResults
  },
  count(state) {
    return state.count
  },
  ltoCount(state) {
    return state.ltoCount
  },
  isOnlyCollectionResults(state) {
    return state.searchCollections.length > 0 && state.results.length < 1
  },
  isSingleCollectionResults(state) {
    return state.isSingleCollectionResults
  },
  getStoreSearchUrl: (state, getters) => (
    query,
    fixedParams,
    validateResults = true
  ) => {
    let params = '?ordering=-score'
    params = fixedParams ? `${params}&${fixedParams}` : params
    const url = `/store/search/${query}/${params}`

    if (!validateResults) {
      return url
    }

    if (state.isSingleCollectionResults || getters.isOnlyCollectionResults) {
      return state.searchCollections[0].url
    } else if (query && state.results.length > 0) {
      return url
    }
    return '/store/'
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
