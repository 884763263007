export function alcoholPercentFiltersToUrl(filter, params) {
  if (filter.from) {
    params.append('alcohol_percent__gte', filter.from)
  }
  if (filter.to) {
    params.append('alcohol_percent__lte', filter.to)
  }
}

function alcoholPercentChipName(alcoholMin, alcoholMax) {
  if (!alcoholMin && !alcoholMax) return 'Alcohol %'
  if (!alcoholMin) return `Alcohol: < ${alcoholMax}%`
  if (!alcoholMax) return `Alcohol: > ${alcoholMin}%`
  return `Alcohol: ${alcoholMin} - ${alcoholMax}%`
}

export function getAlcoholPercentFilterFromMinMax(alcoholMin, alcoholMax) {
  return {
    category: 'alcohol_percent',
    key: 'alcohol_percent_key',
    name: alcoholPercentChipName(alcoholMin, alcoholMax),
    from: alcoholMin,
    to: alcoholMax,
  }
}

export function alcoholPercentUrlToFilters({ store, query }) {
  const alcoholMin = query.alcohol_percent__gte
  const alcoholMax = query.alcohol_percent__lte
  if (!alcoholMin && !alcoholMax) return undefined
  return [getAlcoholPercentFilterFromMinMax(alcoholMin, alcoholMax)]
}
