export function customPriceFiltersToUrl(filter, params) {
  if (filter.from) {
    params.append('price_excl_tax__gte', filter.from)
  }
  if (filter.to) {
    params.append('price_excl_tax__lte', filter.to)
  }
}

function customPriceChipName(priceMin, priceMax) {
  if (!priceMin && !priceMax) return 'Price'
  if (!priceMin) return `< $${priceMax}`
  if (!priceMax) return `> $${priceMin}`
  return `$${priceMin} - $${priceMax}`
}

export function getCustomPriceFilterFromMinMax(priceMin, priceMax) {
  return {
    category: 'custom_price',
    key: 'custom_price_key',
    name: customPriceChipName(priceMin, priceMax),
    from: priceMin,
    to: priceMax,
  }
}

export function customPriceUrlToFilters({ store, query }) {
  const priceMin = query.price_excl_tax__gte
  const priceMax = query.price_excl_tax__lte
  if (!priceMin && !priceMax) return undefined
  return [getCustomPriceFilterFromMinMax(priceMin, priceMax)]
}
