import { urlToFilters } from '~/utils/filters/filters'
import {
  condenseRegionsToAttribute,
  flattenRegions,
  getChildSlugs,
} from '~/utils/filters/regionSlugs'

const state = () => ({
  regions: {}, // regions holds all region facets with the top level continents too
  regionsIndex: [],
  regionsFilters: {},
  selectedRegions: [],
})

const mutations = {
  SET_REGIONS(state, payload) {
    state.regions = payload
    for (const region of Object.values(state.regions)) {
      if (region.regions) {
        region.regions.forEach((r) => (state.regionsFilters[r.slug] = r))
      }
      if (region.unspecifiedItems) {
        region.unspecifiedItems.forEach(
          (r) => (state.regionsFilters[r.slug] = r)
        )
      }
    }

    state.regionsIndex = flattenRegions({ regions: state.regionsFilters })
  },
  SET_SELECTED_REGIONS(state, payload) {
    state.selectedRegions = payload
  },
}

const actions = {
  selectRegion({ commit, state, rootState, getters }, { region }) {
    commit(
      'SET_SELECTED_REGIONS',
      Array.from(
        new Set(state.selectedRegions.concat(getChildSlugs(region)))
      ).filter((s) => s)
    )
  },
  deselectRegion({ commit, state, rootState, getters }, { region }) {
    const deselectSlugs = getChildSlugs(region)
    commit(
      'SET_SELECTED_REGIONS',
      state.selectedRegions.filter((s) => !deselectSlugs.includes(s))
    )
  },
  deselectAllRegions({ commit }) {
    commit('SET_SELECTED_REGIONS', [])
  },
  setSelectedRegionsFromUrl(store, { route }) {
    store.commit(
      'SET_SELECTED_REGIONS',
      urlToFilters({ store, route })
        .filter((f) => f.category === 'region_slug')
        .map((f) => f.key)
    )
  },
}

const getters = {
  regionsIndex(state) {
    return state.regionsIndex
  },
  regionsFilters(state) {
    return state.regionsFilters
  },
  regionsWithSelections(state) {
    const setSelected = (region) => {
      const newRegion = {
        ...region,
        regions: undefined,
      }
      // Set children
      if (region.regions) {
        newRegion.regions = {}
        for (const r in region.regions) {
          newRegion.regions[r] = setSelected(region.regions[r])
        }
      }
      newRegion.checked =
        (newRegion.slugs &&
          newRegion.slugs.some((s) => state.selectedRegions.includes(s))) ||
        (newRegion.regions &&
          Object.values(newRegion.regions).some((s) => s.checked))
      return newRegion
    }

    return Object.entries(state.regions).reduce((filters, [key, filter]) => {
      filters[key] = setSelected(filter)
      return filters
    }, {})
  },
  selectedRegionSlugs(state) {
    return state.selectedRegions
  },
  selectedRegionChips(state) {
    return condenseRegionsToAttribute(
      state.regionsFilters,
      state.selectedRegions,
      'chipName'
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
