export function drinkingWindowFiltersToUrl(filter, params) {
  if (filter.from) {
    params.append('drinking_window_end__gte', filter.from)
  }
  if (filter.to) {
    params.append('drinking_window_start__lte', filter.to)
  }
}

function drinkingWindowChipName(yearMin, yearMax) {
  if (!yearMin && !yearMax) return 'Drinking Window'
  if (!yearMin) return `Drinking Window: < ${yearMax}`
  if (!yearMax) return `Drinking Window: > ${yearMin}`
  return `Drinking Window: ${yearMin} - ${yearMax}`
}

export function getDrinkingWindowFilterFromMinMax(yearMin, yearMax) {
  return {
    category: 'drinking_window',
    key: 'drinking_window_key',
    name: drinkingWindowChipName(yearMin, yearMax),
    from: yearMin,
    to: yearMax,
  }
}

export function drinkingWindowUrlToFilters({ store, query }) {
  const yearMin = query.drinking_window_end__gte
  const yearMax = query.drinking_window_start__lte
  if (!yearMin && !yearMax) return undefined
  return [getDrinkingWindowFilterFromMinMax(yearMin, yearMax)]
}
