import { getKeyForUrl } from './default'
import { deslugify } from '~/../common/utils/slugify'

// These are default categories that we always want to show up in the Types field for filters
// Keep them sorted by "position"
export const DEFAULT_CATEGORIES = [
  { key: 'red-wine', doc_count: 0 },
  { key: 'white-wine', doc_count: 0 },
  { key: 'rose', doc_count: 0 },
  { key: 'sparkling', doc_count: 0 },
  { key: 'sweet-wine', doc_count: 0 },
  { key: 'fortified', doc_count: 0 },
  { key: 'sake', doc_count: 0 },
]

// These are default varietals that we always want to show up in the Variety field for filters
export const DEFAULT_VARIETALS = [
  {
    key: 'cabernet-sauvignon',
    doc_count: 0,
    name: 'Cabernet Sauvignon',
  },
  {
    key: 'chardonnay',
    doc_count: 0,
    name: 'Chardonnay',
  },
  {
    key: 'merlot',
    doc_count: 0,
    name: 'Merlot',
  },
  {
    key: 'pinot-grigio',
    doc_count: 0,
    name: 'Pinot Grigio',
  },
  {
    key: 'pinot-noir',
    doc_count: 0,
    name: 'Pinot Noir',
  },
  {
    key: 'red-blend',
    doc_count: 0,
    name: 'Red Blend',
  },
  {
    key: 'riesling',
    doc_count: 0,
    name: 'Riesling',
  },
  {
    key: 'sauvignon-blanc',
    doc_count: 0,
    name: 'Sauvignon Blanc',
  },
  {
    key: 'syrah',
    doc_count: 0,
    name: 'Syrah',
  },
  {
    key: 'shiraz',
    doc_count: 0,
    name: 'Shiraz',
  },
  {
    key: 'zinfandel',
    doc_count: 0,
    name: 'Zinfandel',
  },
]

export function updateFacetDefaults(facetCategory, facets, apiData) {
  for (const data of apiData) {
    const catIndex = facets[facetCategory].findIndex(
      (obj) => obj.key === data.key
    )
    if (catIndex < 0) {
      facets[facetCategory].push(data)
    } else {
      if (data.name) {
        facets[facetCategory][catIndex].name = data.name
      }
      if (data.show_in_filter_dropdown) {
        facets[facetCategory][catIndex].show_in_filter_dropdown =
          data.show_in_filter_dropdown
      }
      if (data.position) {
        facets[facetCategory][catIndex].position = data.position
      }
      facets[facetCategory][catIndex].doc_count = data.doc_count
    }
  }
}

export function updateVarietalFacetDefaults(facets, apiData, varietals) {
  for (const data of apiData) {
    const catIndex = facets.varietals.findIndex((obj) => obj.key === data.key)
    const varietal = varietals.find((obj) => obj.slug === data.key)
    if (varietal) {
      data.name = varietal.name
      data.show_in_filter_dropdown = varietal.show_in_filter_dropdown
      data.position = varietal.position
    }
    if (catIndex < 0) {
      facets.varietals.push(data)
    } else {
      facets.varietals[catIndex].name = data.name
      facets.varietals[catIndex].show_in_filter_dropdown =
        data.show_in_filter_dropdown
      facets.varietals[catIndex].position = data.position
      facets.varietals[catIndex].doc_count = data.doc_count
    }
  }
}

const CATEGORIES = {
  categories: 'categories',
  occasions: 'occasion',
  occasion: 'occasion',
  is_offer: 'is_offer',
  food_pairings: 'food_pairings',
  varietals: 'varietals',
  varietal_categories: 'varietal_categories',
  wineries: 'wineries',
  vintage: 'vintage',
  expert_reviewers: 'expert',
}

const FILTERS = {
  occasion: 'occasions',
  expert: 'expert_reviewers',
}

export function categoryFiltersToUrl(filters) {
  if (filters.length !== 1 || !CATEGORIES[filters[0].category]) return undefined
  const filter = filters[0]
  if (['categories', 'varietal_categories'].includes(filter.category)) {
    return `${getKeyForUrl(filter)}/`
  }
  return `${CATEGORIES[filter.category]}/${getKeyForUrl(filter)}/`
}

export function categoryUrlToFilters({ store, route }) {
  if (!route.params.category || !store.getters.searchFilters) return undefined
  let matchingFilter = store.getters.searchFilters.find(
    (filter) =>
      (filter.category === route.params.category &&
        filter.key === route.params.slug) ||
      (filter.category === 'categories' &&
        route.params.category === 'category' &&
        filter.key === route.params.slug) ||
      (filter.category === 'varietal_categories' &&
        route.params.category === 'varietal_categories' &&
        filter.key === route.params.slug) ||
      (['categories', 'varietal_categories'].includes(filter.category) &&
        route.params.category === filter.key &&
        !route.params.slug) ||
      (filter.category === 'occasions' &&
        route.params.category === 'occasion' &&
        filter.key === route.params.slug) ||
      (filter.category === 'occasions' &&
        filter.key === route.params.slug &&
        !route.params.slug) ||
      (filter.category === 'is_offer' &&
        route.params.category === 'is_offer' &&
        filter.key === (route.params.slug === 'true' ? 1 : 0))
  )
  if (
    !matchingFilter &&
    route.params.slug &&
    [
      'occasion',
      'varietals',
      'wineries',
      'occasions',
      'food_pairings',
      'vintage',
      'expert',
    ].includes(route.params.category)
  ) {
    // Create a filter
    let name = null
    if (
      route.params.category === 'occasion' &&
      store.getters.thisOccasion.slug === route.params.slug
    ) {
      name = store.getters.thisOccasion.name
    } else {
      name = deslugify(route.params.slug)
    }
    matchingFilter = {
      key: route.params.slug,
      name,
      category: FILTERS[route.params.category] || route.params.category,
    }
  } else if (
    !matchingFilter &&
    !route.params.slug &&
    store.getters.facetNames
  ) {
    // See if the category matches a filter type we want to 404 on:
    const varietalCategoryName =
      store.getters.facetNames.searchable_varietal_categories[
        route.params.category
      ]
    if (varietalCategoryName) {
      matchingFilter = {
        key: route.params.category,
        name: varietalCategoryName,
        category: 'varietal_categories',
      }
    }
  }
  if (!matchingFilter) return undefined
  return [matchingFilter]
}
