const state = () => ({
  pptoToastOpen: false,
})

const mutations = {
  SET_PPTO_TOAST_OPEN(state, open) {
    state.pptoToastOpen = open
  },
}

const actions = {
  setPptoToastOpen({ commit }, open) {
    commit('SET_PPTO_TOAST_OPEN', open)
  },
}

const getters = {
  pptoToastModalOpen(state, getters) {
    return (
      state.pptoToastOpen &&
      getters.firstPptoOrder &&
      getters.firstPptoOrder.requested_delivery_date &&
      getters.showPPTO &&
      getters.pptoLink
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
