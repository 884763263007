import Vuex from 'vuex'
import cart from './modules/cart'
import checkoutState from './modules/checkoutState'
import savedSearch from './modules/savedSearch'
import pptoToast from './modules/pptoToast'
import search from '~/store/modules/search'
import homepageSearch from '~/store/modules/homepageSearch'
import metadata from '~/store/modules/metadata'
import filters from '~/store/modules/filters'
import regionsFilters from '~/store/modules/regionsFilters'
import allRegionsFilters from '~/store/modules/allRegionsFilters'
import login from '~/../common/store/login'
import accountOrder from '~/../common/store/accountOrder'
import serverInit from '~/store/modules/serverInit'
import promoBanner from '~/store/modules/promoBanner'
import product from '~/store/modules/product'
import wineClub from '~/store/modules/wineClub'
import subscriptionGifting from '~/../common/store/subscriptionGifting'
import giftCard from '~/store/modules/giftCard'
import checkout from '~/../common/store/checkout'
import notifications from '~/store/modules/notifications'
import header from '~/store/modules/header'
import footer from '~/store/modules/footer'
import pptoOrder from '~/store/modules/pptoOrder'
import workGroupTracker from '~/../common/store/workGroupTracker'
import myCellar from '~/../common/store/myCellar'
import futures from '~/store/modules/futures'

const store = () =>
  new Vuex.Store({
    modules: {
      workGroupTracker,
      cart,
      checkoutState,
      savedSearch,
      search,
      homepageSearch,
      metadata,
      filters,
      regionsFilters,
      allRegionsFilters,
      login,
      accountOrder,
      serverInit,
      promoBanner,
      product,
      wineClub,
      subscriptionGifting,
      giftCard,
      checkout,
      notifications,
      header,
      footer,
      pptoToast,
      pptoOrder,
      myCellar,
      futures,
    },
  })

export default store
