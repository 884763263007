const state = () => ({
  env: {},
  buildEnv: '',
  inMobileApp: false,
})

const mutations = {
  setEnv(state, env) {
    state.env = env
  },
  setBuildEnv(state, env) {
    state.buildEnv = env
  },
  SET_IN_MOBILE_APP(state, inMobile) {
    state.inMobileApp = inMobile
  },
}

const actions = {
  nuxtServerInit({ commit }) {
    if (process.server) {
      if (process.env.NUXT_ENV_BUILD_HASH) {
        commit('setEnv', {
          buildHash: JSON.parse(process.env.NUXT_ENV_BUILD_HASH),
        })
      } else {
        commit('setEnv', {
          buildHash: false,
        })
      }
      commit('setBuildEnv', process.env.ENV)
    }
  },
  setInMobileApp({ commit }, inMobile) {
    commit('SET_IN_MOBILE_APP', inMobile)
  },
}
const getters = {
  env(state) {
    return state.env
  },
  buildEnv(state) {
    return state.buildEnv
  },
  inMobileApp(state) {
    return state.inMobileApp
  },
  isProdEnv(state) {
    return state.buildEnv === 'prod'
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
