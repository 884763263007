// needs to be after metadata middleware
export default function ({ $cookies, route, req, $moment, store, $auth }) {
  if (
    process.server &&
    !$cookies.get('wa_referrer') &&
    !$cookies.get('wa_landing_url')
  ) {
    const referrer = req.headers.referer
    const referrerDate = $moment().format('ddd, DD MMM YYYY HH:mm:ss')
    $cookies.set('wa_referrer', referrer)
    $cookies.set('wa_referrer_date', referrerDate)
    $cookies.set('current_wa_referrer', referrer)
    $cookies.set('current_wa_referrer_date', referrerDate)
    $cookies.set('wa_landing_url', route.fullPath)
    $cookies.set('current_wa_landing_url', route.fullPath)
    if (route.query.utm_medium === 'email') {
      $cookies.set('wa_from_email', 'yes')
    }
  }
}
