const { isProductionBuild } = require('./build.js')

let segmentKey = 'locked-IU2KNBb6XGyRkyVsw7N8d9ZPRDmByFVi'
if (isProductionBuild()) {
  segmentKey = 'iNFD1ckUC5yOn2DPX8Jk1c6Fq6uAslNB'
}

const facebook = {
  appId: "'567741136763695'",
  version: "'v2.8'",
  xbfml: 'true',
}

const googleOptimizeContainerId = 'GTM-WX4K3HC'

module.exports = {
  segmentKey,
  facebook,
  googleOptimizeContainerId,
}
