export function dessertFortifiedFiltersToUrl(filters) {
  if (
    filters.length === 2 &&
    filters.every(
      (filter) =>
        filter.category === 'categories' &&
        ['sweet-wine', 'fortified'].includes(filter.key)
    )
  ) {
    return `dessert-fortified/`
  }
  return undefined
}

export function dessertFortifiedUrlToFilters({ store, route }) {
  if (
    route.params.category !== 'dessert-fortified' ||
    !store.getters.searchFilters
  )
    return undefined
  return store.getters.searchFilters.filter(
    (filter) =>
      filter.category === 'categories' &&
      ['sweet-wine', 'fortified'].includes(filter.key)
  )
}

export function sweetFortifiedUrlToFilters({ store, route }) {
  if (
    route.params.category !== 'sweet-fortified' ||
    !store.getters.searchFilters
  )
    return undefined
  return store.getters.searchFilters.filter(
    (filter) =>
      filter.category === 'categories' &&
      ['sweet-wine', 'fortified'].includes(filter.key)
  )
}
