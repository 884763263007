export function customPointsFiltersToUrl(filter, params) {
  if (filter.from) {
    params.append('expert_rating_range__gte', filter.from)
  }
  if (filter.to) {
    params.append('expert_rating_range__lte', filter.to)
  }
}

function customPointsChipName(pointsMin, pointsMax) {
  if (!pointsMin && !pointsMax) return 'Points'
  if (!pointsMin) return `Points: < ${pointsMax}`
  if (!pointsMax) return `Points: > ${pointsMin}`
  return `Points: ${pointsMin} - ${pointsMax}`
}

export function getCustomPointsFilterFromMinMax(pointsMin, pointsMax) {
  return {
    category: 'custom_points',
    key: 'custom_points_key',
    name: customPointsChipName(pointsMin, pointsMax),
    from: pointsMin,
    to: pointsMax,
  }
}

export function customPointsUrlToFilters({ store, query }) {
  const pointsMin = query.expert_rating_range__gte
  const pointsMax = query.expert_rating_range__lte
  if (!pointsMin && !pointsMax) return undefined
  return [getCustomPointsFilterFromMinMax(pointsMin, pointsMax)]
}
