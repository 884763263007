const state = () => ({
  notifications: [],
  notificationsOpen: false,
  notificationsPreviousOpen: false,
  mobileNotificationsOpen: false,
})

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_NOTIFICATIONS_OPEN(state, payload) {
    state.notificationsOpen = payload
  },
  SET_MOBILE_NOTIFICATIONS_OPEN(state, payload) {
    state.mobileNotificationsOpen = payload
  },
  SET_NOTIFICATIONS_PREVIOUS_OPEN(state, payload) {
    state.notificationsPreviousOpen = payload
  },
  SET_NOTIFICATION_TOUCHED(state, payload) {
    const notification = state.notifications.find(
      (x) => x === payload.notification
    )
    if (notification) {
      notification.touched_at = payload.touched_at
    }
  },
  SET_NOTIFICATION_HIDDEN(state, payload) {
    // Remove notification from array to hide in UI
    const idx = state.notifications.indexOf(payload)
    if (idx > -1) {
      state.notifications.splice(idx, 1)
    }
  },
  SET_ALL_PRODUCT_NOTIFICATIONS_VIEWED(state, payload) {
    state.notifications.forEach((x) => {
      if (x.type === 'product') {
        x.viewed_at = payload
      }
    })
  },
  SET_ALL_PRODUCT_NOTIFICATIONS_TOUCHED(state, payload) {
    state.notifications.forEach((x) => {
      if (x.type === 'product') {
        x.touched_at = payload
      }
    })
  },
  REMOVE_NOTIFICATION(state, payload) {
    const index = state.notifications.indexOf(payload)
    if (index > -1) {
      state.notifications.splice(index, 1)
    }
  },
}

const actions = {
  setNotifications({ commit }, payload) {
    commit('SET_NOTIFICATIONS', payload)
  },
  setNotificationsOpen({ commit }, payload) {
    commit('SET_NOTIFICATIONS_OPEN', payload)
  },
  setMobileNotificationsOpen({ commit }, payload) {
    commit('SET_MOBILE_NOTIFICATIONS_OPEN', payload)
  },
  setNotificationTouched({ commit }, payload) {
    commit('SET_NOTIFICATION_TOUCHED', payload)
  },
  setNotificationHidden({ commit }, payload) {
    commit('SET_NOTIFICATION_HIDDEN', payload)
  },
  setAllProductNotificationsViewed({ commit }, payload) {
    commit('SET_ALL_PRODUCT_NOTIFICATIONS_VIEWED', payload)
  },
  setAllProductNotificationsTouched({ commit }, payload) {
    commit('SET_ALL_PRODUCT_NOTIFICATIONS_TOUCHED', payload)
  },
  removeNotification({ commit }, payload) {
    commit('REMOVE_NOTIFICATION', payload)
  },
  setNotificationsPreviousOpen({ commit }, payload) {
    commit('SET_NOTIFICATIONS_PREVIOUS_OPEN', payload)
  },
}

const getters = {
  notifications(state) {
    return state.notifications
  },
  productNotifications(state) {
    return state.notifications.filter((x) => x.type === 'product')
  },
  notificationsOpen(state) {
    return state.notificationsOpen
  },
  notificationsPreviousOpen(state) {
    return state.notificationsPreviousOpen
  },
  mobileNotificationsOpen(state) {
    return state.mobileNotificationsOpen
  },
  newProductNotifications(state, getters) {
    return getters.productNotifications.filter((x) => !x.viewed_at)
  },
  notificationBell(state) {
    return state.notifications.find((x) => x.type === 'alert_bell')
  },
  allProductNotificationsViewed(state, getters) {
    return getters.newProductNotifications.length === 0
  },
  allProductNotificationsTouched(state, getters) {
    return (
      getters.productNotifications.filter((x) => !x.touched_at).length === 0
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
