// Welcome to flavor town
export const FLAVOR_VALUE_TO_LABEL_MAPPING = [
  { value: 0, label: 'Very Low' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' },
]

export function flavorFiltersToUrl(filter, params) {
  if (Number(filter.from)) {
    params.append(`${filter.key}_scale__gte`, filter.from)
  }
  if (filter.to !== undefined && filter.to !== null && Number(filter.to) < 3) {
    params.append(`${filter.key}_scale__lte`, filter.to)
  }
}

function flavorChipName(flavorMin, flavorMax, style) {
  const getValueLabel = (value) => {
    const valueLabel = FLAVOR_VALUE_TO_LABEL_MAPPING.find(
      (x) => x.value === Number(value)
    )
    return valueLabel ? valueLabel.label : value
  }
  const capStyle = style.charAt(0).toUpperCase() + style.slice(1)
  if (
    !flavorMin &&
    (flavorMax === undefined || flavorMax === null || flavorMax >= 3)
  )
    return capStyle
  if (!flavorMin) {
    if (flavorMax === 0) return `${capStyle}: ${getValueLabel(flavorMax)}`
    return `${capStyle}: < ${getValueLabel(flavorMax)}`
  }
  if (flavorMin === flavorMax) return `${capStyle}: ${getValueLabel(flavorMin)}`
  if (flavorMax === undefined || flavorMax === null || flavorMax >= 3) {
    if (flavorMin >= 3) return `${capStyle}: ${getValueLabel(flavorMin)}`
    return `${capStyle}: > ${getValueLabel(flavorMin)}`
  }
  return `${capStyle}: ${getValueLabel(flavorMin)} - ${getValueLabel(
    flavorMax
  )}`
}

export function getFlavorFilterFromMinMax(flavorMin, flavorMax, style) {
  return {
    category: 'flavor_profile',
    key: style,
    name: flavorChipName(flavorMin, flavorMax, style),
    from: flavorMin,
    to: flavorMax,
  }
}

export function flavorUrlToFilters({ store, query }) {
  let flavorFilters = []
  const flavorRegex = /([a-zA-Z]*)_scale__.*/

  const setFlavorFilter = (filters, key, value, style) => {
    let filter = filters.find(
      (x) => x.category === 'flavor_profile' && x.key === style
    )
    if (!filter) {
      filter = {
        category: 'flavor_profile',
        key: style,
      }
      filters.push(filter)
    }
    if (key.includes('gte')) {
      filter.from = Number(value)
    } else {
      filter.to = Number(value)
    }
    filter.name = flavorChipName(filter.from, filter.to, style)
    return filters
  }

  new URLSearchParams(query).forEach((value, key) => {
    if (flavorRegex.test(key)) {
      const style = flavorRegex.exec(key)[1]
      flavorFilters = setFlavorFilter(flavorFilters, key, value, style)
    }
  })

  return flavorFilters
}
